@import "../assets/breakpoints";
@import "../assets/color-variables";

.search-bar {
  position: relative;
  display: none;
}

.search-bar,
.search-bar--banner {
  .input-container {
    position: relative;
    flex-grow: 1;
    margin-left: 20px;
  }
  &__input {
    width: 100%;
    background-color: $color-search-bg;
    height: 50px;
    border-radius: 28px;
    border: 2px solid #edf1f2;
    padding-right: 64px;
    padding-left: 36px;
  }
  &__submit {
    width: 64px;
    height: 40px;
    border-radius: 28px;
    padding: 0px;
    position: absolute;
    top: 5px;
    right: 10px;
    border: none;
    background-color: $color-success;
    cursor: pointer;
  }
  &__magnifier {
    width: 20px;
    height: 20px;
    color: #555555;
    position: absolute;
    left: 15px;
    top: 16px;
  }
}

.search-bar--banner {
  font-size: 35px;
  height: 74px;
  display: flex;
  justify-content: left;
  padding-left: auto;
  align-items: center;
  background: transparent url("../../header-bg.png") 0% 0% repeat padding-box;
  &::before {
    color: white;
    content: "ÇiçekSepeti";
    margin-left: 140px;
  }
  &__input,
  &__submit {
    display: none;
  }
}

@media (min-width: $breakpoint-sm) {
  .search-bar {
    display: flex;
    flex: 1;
    align-items: center;

    &__input {
      margin-left: 32px;
      display: flex;
      flex-grow: 1;
      margin: auto auto;
      max-width: 872px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .search-bar--banner {
    background: $banner-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: none;
    }

    &__input,
    &__submit {
      display: block;
    }

    &__input {
      width: 100%;
      min-width: 500px;
    }
  }
}

@media (max-width: 500px) {
  .search-bar--banner {
    &::before {
      margin-left: 30px;
    }
    &__input {
      min-width: 300px;
    }
  }
}
