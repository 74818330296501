body {
  margin: 0;
  padding: 0;
  font-variant-numeric: lining-nums;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "kern";
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
  line-height: normal;
  letter-spacing: normal;
}

button,
input,
optgroup,
select,
textarea {
  outline: none;
}

input[type="text"] {
  -webkit-appearance: none;
}

ul {
  padding: 0px;
}

p {
  margin-block-start: 0%;
  margin-block-end: 0%;
}
a {
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: "Source Sans Pro", sans-serif, Helvetica, Arial;
}
