@import "../assets/color-variables";
@import "../assets/breakpoints";

.header {
  color: white;
  padding: 20px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: $brand-color;

  &__hamburger {
    font-size: 32px;
    color: white;
    cursor: pointer;
  }

  &__brand-logo {
    display: none;

    &--white {
      display: block;
    }
  }
}

.brand-banner {
  padding-left: 140px;
  font-size: 35px;
  background: transparent url("../../header-bg.png") 0% 0% no-repeat padding-box;
  height: 74px;
}

@media (min-width: $breakpoint-sm) {
  .header {
    justify-content: space-between;
    background: white;
    padding: 20px 140px;

    &__hamburger {
      display: none !important;
    }

    &__brand-logo {
      display: block;
      &--white {
        display: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .header {
    padding: 20px 30px;
  }
}
