@import "../assets/breakpoints";

.campaigns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 70px;

  &__campaign {
    background: #e8f1ff;
    width: auto;
    height: 152px;
    display: flex;
    border-radius: 10px;
    padding: 10px;
    margin: auto;
    margin-bottom: 20px;

    &:first-child {
      background: #ffeae8;
    }
    &:last-child {
      background: #e2f7e1;
    }
  }

  &__image {
    width: 166px;
    height: 132px;
    margin: auto auto;
  }

  &__details {
    margin: auto auto;
    width: 216px;
    padding: 0px 32px;
    color: #707070;
  }

  &__button-pill {
    width: 104px;
    height: 30px;
    border-radius: 15px;
    background: white;
    border: none;
    color: #e64e41;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0px;
  }
}

@media (min-width: $breakpoint-sm) {
  .campaigns {
    padding: 20px 140px;
    &__campaign {
      max-width: 480px;
    }
  }
}
@media (max-width: $breakpoint-sm) {
  .campaigns {
    display: flexbox;
    justify-content: center;
    img {
      display: none;
    }
    &__campaign {
      max-width: 320px;
    }
  }
}
