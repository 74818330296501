@import "../assets/color-variables";
@import "../assets/breakpoints";

.categories {
  display: none;
  &__hamburger {
    padding: 36px 140px 10px;
    color: #044dc3;
    &::after {
      margin-left: 8px;
      content: "Kategoriler";
      font-family: "Source Sans Pro", sans-serif, Helvetica, Arial;
    }
  }

  &__buttons {
    padding: 10px 140px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
    column-gap: 24px;
    row-gap: 20px;
  }

  &__button {
    background: white;
    cursor: pointer;
    height: 50px;
    border: 1px solid #e2e7e9;
    border-radius: 10px;
    color: #707070;
    opacity: 1;

    &--selected {
      background: $brand-color !important;
      color: white;
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .categories {
    display: block;
  }
}
