.basket {
  margin-left: 32px;
  position: relative;
  &__button {
    display: none;
    cursor: pointer;
    border: none;
  }
  &__logo {
    font-size: 28px;
    color: white;
  }
  &__count {
    display: inline-block;
    line-height: 25px;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: $color-warn;
    width: 25px;
    height: 25px;
    border-radius: 99px;
  }
  &__information {
    display: none;
    position: absolute;
    margin-top: 15px;
    left: -180px;
    width: 343px;
    height: 69px;
    background-color: #f54257;
    border-radius: 16px;
    text-align: center;
    line-height: 69px;
    &:before {
      content: "";
      width: 0px;
      height: 0px;
      border-bottom: 10px solid #f54257;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      top: -20px;
      right: 70px;
    }
  }
  &__thunder {
    color: $color-thunder;
    margin-right: 4px;
  }

  &__bar {
    width: 293px;
    height: 5px;
    background: #d01d32;
    border-radius: 22px;
    margin: -16px auto 0px;
    &--progress {
      border-radius: 22px;
      background: #ffce00;
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .basket {
    &__information {
      display: block;
    }
    &__logo {
      display: none !important;
    }
    &__button {
      display: block;
      background: $brand-color;
      width: 144px;
      height: 48px;
      border-radius: 28px;
      font-size: 16px;
      color: white;
    }

    &__cart {
      margin: 0px 4px 0px 24px;
    }

    &__details {
      display: flex;
      align-items: center;
    }
  }
}
